import * as React from "react";
import classNames from "classnames";
import { Link } from "gatsby";
import Container from "./Container";

import { FacebookIcon, InstagramIcon } from "../../images/svgs";
// markup
const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <footer className={classNames("pageFooter")}>
      <Container>
        <div className="footerBranding">
          <address>
            White Walls,
            <br />
            Solomon's Cave, Gr. Floor,
            <br /> Tuikual South, Aizawl,
            <br />
            Mizoram
          </address>
        </div>

        <div className="footerNav">
          <ul className="footerMenu">
            <li>
              <Link to="/contact-us">Contact Us</Link>
            </li>
          </ul>
          <ul className="social-links">
            <li>
              <a href="https://www.facebook.com/WhiteWallsAizawl">
                <FacebookIcon />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/whitewallsaizawl/">
                <InstagramIcon />
              </a>
            </li>
          </ul>
        </div>
      </Container>
      <div className="footerInfo">
        <Container>
          <div>
            &copy;&nbsp;{year} White Walls.&nbsp;&nbsp;All Rights Reserved.
          </div>
          <div>
            <ul className="policies">
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
            </ul>
          </div>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;
