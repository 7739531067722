import * as React from "react";
import classNames from "classnames";
import Container from "./Container";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

// markup
const Header = ({ menu, toggleMenu }) => {
  return (
    <header className={classNames("pageHeader")}>
      <Container>
        <Link to="/" className="header-logo">
          <StaticImage
            src="../../images/white-walls-interior-design-aizawl-mizoram-.png"
            alt=""
          />
        </Link>
        <nav>
          <div className="mainMenu">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/interior-design-service-aizawl-mizoram">
                  Interior Design
                </Link>
              </li>

              <li>
                <a href="https://www.viewsmizoram.com" target="_blank">
                  uPVC Doors &amp; Windows
                </a>
              </li>

              <li>
                <Link to="/shop-interior-hardware-accessories">
                  Hardware &amp; Accessories
                </Link>
              </li>

              <li>
                <Link to="/contact-us">Contact Us</Link>
              </li>
            </ul>
          </div>

          <div className="menuToggle" onClick={() => toggleMenu(!menu)}>
            <span />
          </div>
        </nav>
      </Container>
    </header>
  );
};

export default Header;
